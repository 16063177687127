// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Moment from 'moment';


import MainLogo from './assets/img/logo.png';
import CustomBtn from './assets/img/custom-btn.png';

import RegisterBox from './assets/img/register-box.png';

import Package1 from './assets/img/package1.png';
import Package2 from './assets/img/package2.png';
import Package3 from './assets/img/package3.png';
import Package4 from './assets/img/package4.png';
import Package5 from './assets/img/package5.png';
import { isAddress } from 'ethers/lib/utils';

// Pages

// hooks
import { useSignup } from './hooks/useSignup';


function App() {
  AOS.init();
  const queryParameters = new URLSearchParams(window.location.search);
  var referrerWallet = queryParameters.get("referral");

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

  const { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

	const userRegister = async () => {
    if (referrerWallet == null || referrerWallet == "") {
      referrerWallet = "0x687159e4f2786b10c977569bbb3a0c9c72873eaf";
    }
    console.log("Final Wallet:", referrerWallet);
    if (referrerWallet.toLowerCase() == blockchain.account.toLowerCase()) {
      setRegisterFeedback("不能推荐自己的钱包地址！");
      return;
    }
    if (!isAddress(referrerWallet) || referrerWallet.length < 42) {
      setRegisterFeedback("钱包地址不符合标准，请检查链接！");
      return;
    }

    await walletSignup(blockchain.account, referrerWallet);

    setTimeout(() => fetchUserData(), 2000);

	  // blockchain.rideChain.methods.register(referrerWallet).send({
    //   gasLimit: 80000,
    //   maxPriorityFeePerGas: 3000000000,
    //   maxFeePerGas: 3000000000,
    //   from: blockchain.account,
    //   value: 0,
	  // })
	  // // Transaction fail
	  // .once("error", (err) => {

	  // })
	  // // Transaction success
	  // .then((receipt) => {

	  // });
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

	// const fetchUserData = () => {
	//   blockchain.rideChain.methods.getUserInfos(blockchain.account).call()
	//   .then((results) => {
  //     console.log("Connected User Wallet Data", results);
  //     setUserData(results);
	//   });
	// };

  const [investData, setInvestData] = useState([]);
	const fetchInvestData = () => {
	  blockchain.rideChain.methods.getOrderInfos(blockchain.account).call()
	  .then((results) => {
		console.log("Connected User Investment Data", results);
		setInvestData(results);
	  });
	};

  const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

  const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};

  const [approving, setApproving] = useState(false);
	const approveToken = () => {
	  setApproving(true);
	  blockchain.usdtContract.methods
	  .approve("0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4", blockchain.web3.utils.toWei((100000).toString(), "ether"))
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setApproving(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setApproving(false), 5000);
	  });
	};

  const [investAmount, setInvestAmount] = useState(300);

  const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = (_amount, _referrer) => {
    if (!isAddress(_referrer)) {
      console.log("Invalid referrer address.");
      fetchUserData();
      return;
    }
    console.log("Submit deposit with referrer:", _referrer);
	  setInvesting(true);
	  blockchain.rideChain.methods
	  .invest(_amount, false, "0x687159e4f2786b10c977569bbb3a0c9c72873eaf", _referrer)
	  .send({
		gasLimit: 180000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 500000000000000,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setInvesting(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setInvestSuccess(true);
		setTimeout(() => fetchInvestData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	  });
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.rideChain !== null) {
      fetchUserData();
      getUserUSDTBalance();
      checkTokenAllowance();
      fetchInvestData();
    }
  };

  useEffect(() => {
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">
        <Router>
            
          <Routes>
            <Route path="/" element={
              !blockchain.account ? (
                <>
                  <section className="login-bg">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                          <br></br>
                          {blockchain.account === "" || blockchain.rideChain === null ? (
                            <>
                              <button className="btn custom-btn mt-5" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}>
                                <img className="w-100" src={CustomBtn}></img>
                                <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>连接钱包</strong></h5>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  {!userData || userData.referrer == "" ? (
                    <section className="register-bg">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                            <div className="referral-area">
                              {/* <img className="w-100" src={RegisterBox}></img> */}
                              {/* <div className="data-box" style={{ width: "100%", whiteSpace: "nowrap" }}> */}
                              <div className="data-box py-5">
                                <h5 className="text-white">确认推荐人地址:</h5>
                                <MiddleEllipsis><span className="text-white mt-1 mb-3" style={{fontSize:"12px"}}>{referrerWallet ? referrerWallet : "没有推荐人，使用默认地址"}</span></MiddleEllipsis>
                                {!registerSuccess ? (
                                  <>
                                    <p className="error mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                    {!registering ? (
                                      <>
                                        <button className="btn custom-btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" onClick={(e) => {
                                          userRegister();
                                        }}>
                                          <img className="w-100" src={CustomBtn}></img>
                                          <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>注册户口</strong></h5>
                                        </button>
                                      </>
                                    ):(
                                      <></>
                                    )}
                                  </>
                                ):(
                                  <p className="profit-green mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ):(
                    <>

                      {/* Staking Modal */}
                      <div class="modal fade" id="stakeModal" tabindex="-1" data-keyboard="false" aria-labelledby="stakeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content invest-modal py-3">
                            <div class="modal-body text-center px-3">
                              {allowance < investAmount*1000000000000000000 ? (
                                <>
                                  {!approving ? (
                                    <>
                                      <h5 className="text-white mb-0">授权 USDT 支付</h5>
                                      <p className="text-white mb-0">此手续只需完成一次</p>
                                      <button className="btn custom-btn mt-3" onClick={(e) => {
                                        approveToken();
                                      }}>
                                        <img className="w-100" src={CustomBtn}></img>
                                        <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>授权</strong></h5>
                                      </button>
                                    </>
                                  ):(
                                    <p className="yellow mb-0" style={{fontSize:"16px"}}>授权手续正在进行中。。。</p>
                                  )}

                                </>
                              ):(
                                <>
                                  {!investing ? (
                                    <>	
                                      {!investSuccess ? (
                                        <>
                                          <h5 className="text-white">确认智能挖矿</h5>
                                          <h3 className="blue mb-0">{investAmount} USDT</h3>
                                          {/* {investAmount >= 10 && investAmount < 1000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.6%~0.8%</strong></span></p> : ""}
                                          {investAmount >= 1000 && investAmount < 3000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.7%~0.9%</strong></span></p> : ""}
                                          {investAmount >= 3000 && investAmount < 5000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.8%~1.0%</strong></span></p> : ""}
                                          {investAmount >= 5000 && investAmount < 10000 ? <p className="text-white mb-0" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>0.9%~1.2%</strong></span></p> : ""}
                                          {investAmount >= 10000 ? <p className="text-white" style={{fontSize:"18px"}}>挖矿收益：<span className="profit-green"><strong>1.0%~1.3%</strong></span></p> : ""} */}
                                          {investAmount > usdtBalance/1000000000000000000 ? (
                                            <p className="error mt-2 mb-0" style={{fontSize:"18px"}}><strong>不足够USDT购买配套</strong></p>
                                          ):(
                                            <button className="btn custom-btn mt-3" onClick={(e) => {
                                              investUSDT(investAmount, userData.referrer);
                                            }}>
                                              <img className="w-100" src={CustomBtn}></img>
                                              <h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>购买</strong></h5>
                                            </button>
                                          )}
                                          <p className="text-white mt-2 mb-0" style={{fontSize:"14px"}}><span className="text-gray">我的钱包: </span>{parseFloat(usdtBalance/1000000000000000000).toFixed(2)} USDT</p>

                                        </>
                                      ):(
                                        <>
                                          <span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2"></i></span>
                                          <h5 className="text-white mt-2 mb-0">成功购买智能挖矿配套，请查看详情。</h5>
                                          <button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
                                            setInvestAmount(300);
                                            checkTokenAllowance();
                                            setTimeout(() => setInvestSuccess(false), 1000);
                                          }}>
                                            <img className="w-100" src={CustomBtn}></img>
                                            <h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>完成</strong></h5>
                                          </button>
                                        </>
                                      )}

                                    </>
                                  ):(
                                    <p className="yellow mb-0" style={{fontSize:"14px"}}>购买智能挖矿正在进行中。。。</p>
                                  )}

                                </>
                              )}



                            </div>
                          </div>
                        </div>
                      </div>

                      <section className="package-bg">
                        <div className="container">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-white">我的</h3>
                              <div className="data-box text-left">
                                <h6 className="blue mb-0">钱包地址：</h6>
                                <MiddleEllipsis><span className="text-white" style={{fontSize:"12px"}}>{blockchain.account}</span></MiddleEllipsis>
                                <h6 className="blue mt-3 mb-0">推荐链接：</h6>
                                {/* {userData && userData.totalDeposit <= 0 ? (
                                  <>
                                    <span className="yellow" style={{fontSize:"12px"}}>用户必须先购买智能挖矿配套后才能开始推荐</span>
                                  </>
                                ):(
                                  <> */}
                                    <MiddleEllipsis><span className="text-white" style={{fontSize:"12px"}}>https://ridechain.io?referral={blockchain.account}</span></MiddleEllipsis>
                                    <CopyToClipboard 
                                      text={"https://ridechain.io?referral="+blockchain.account.toLowerCase()}
                                      onCopy={() => copyReferral()}>
                                      <button className="btn mt-2 btn-primary" onClick={(e) => {
                                      }}>
                                        {!copied ? (
                                          <p className="mb-0" style={{fontSize:"12px"}}>复制链接</p>
                                        ):(
                                          <p className="mb-0" style={{fontSize:"12px"}}>{copyText}</p>
                                        )}
                                      </button>
                                    </CopyToClipboard>
                                  {/* </>
                                )} */}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 text-center mt-5">
                              <h3 className="text-white">智能挖矿</h3>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <button className="btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
                                setInvestSuccess(false);
                                setInvestAmount(300);
                              }}>
                                <img className="w-100" src={Package1}></img>
                              </button>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <button className="btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
                                setInvestSuccess(false);
                                setInvestAmount(1000);
                              }}>
                                <img className="w-100" src={Package2}></img>
                              </button>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <button className="btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
                                setInvestSuccess(false);
                                setInvestAmount(3000);
                              }}>
                                <img className="w-100" src={Package3}></img>
                              </button>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <button className="btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
                                setInvestSuccess(false);
                                setInvestAmount(5000);
                              }}>
                                <img className="w-100" src={Package4}></img>
                              </button>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <button className="btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
                                setInvestAmount(10000);
                              }}>
                                <img className="w-100" src={Package5}></img>
                              </button>
                            </div>
                          </div>

                          <div className="row mt-5">
                            <div className="col-12 text-center">
                              <h3 className="text-white mt-5">我的挖矿配套</h3>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 mt-2">

                              {investData && investData.length > 0 ? (
                                <div className="table-container details-table">
                                  <table className="table borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>#</p></th>
                                        <th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>挖矿配套</p></th>
                                        <th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>每日收益</p></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {investData && investData.map((investment, index) => (
                                        <tr key={index}>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{index+1}</p></td>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{parseFloat(investment.amount).toFixed(2)} USDT</p></td>
                                          <td className="py-2"><p className="yellow mb-0" style={{fontSize:"12px"}}>还未开始</p></td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ):(
                                <div className="data-box">
                                  <div className="row">
                                    <div className="col-12 text-center">
                                      <p className="text-gray mb-0">此用户没有挖矿配套。</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              
                            </div>
                          </div>

                        </div>
                      </section>
                    </>
                  )}

                </>
              )
            }/>
          </Routes>

        </Router>
      </div>
    </div>
  );
}

export default App;